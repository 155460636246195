import { createStore } from "effector";
import Cookies from "js-cookie";
import { shared } from "shared";
import { State } from "./application-settings.types";

const cookieMapStyleId = Cookies.get("map-style");
const cookieIsEmptyPieces = Cookies.get("telemetry-is-empty-pieces");
const cookieIsShowConnection = Cookies.get("show-connection-detector-graph");
const cookieIsShowInterval = Cookies.get("show-interval");
const cookieIsnumberOfEdges = 1;

const initialState: State = {
  isPopupVisible: false,
  activeMapStyle: shared.map.mapStyles.find(({ id }) => id === cookieMapStyleId) ?? shared.map.defaultStyle,
  telematics: {
    isEmptyPieces: cookieIsEmptyPieces ? cookieIsEmptyPieces === "true" : true,
  },
  connectionDetectorGraph: {
    isShowConnection: cookieIsShowConnection ? cookieIsShowConnection === "true" : true,
  },
  interval: {
    isShowInterval: cookieIsShowInterval ? cookieIsShowInterval === "true" : true,
  },
  numberOfEdges: {
    /** Будет ли отображаться интервал  */
    value: cookieIsnumberOfEdges,
  },
};

export const store = createStore<State>(initialState);
