import React from "react";
import { SingleButton, BUTTON_FORM, IconSVGName } from "components";
import * as Components from "./components";
import * as hooks from "./application-settings.hooks";
import * as consts from "./application-settings.consts";
import "./application-settings.scss";

export const ApplicationSettings = React.memo(() => {
  const { containerRef, isPopupVisible, handleApplicationSettingsButtonClick } = hooks.usePopupLogic();

  return (
    <div ref={containerRef} className={consts.baseClass}>
      <Components.ApplicationSettingsPopup isVisible={isPopupVisible}>
        <Components.MapStyles />
        <Components.Heading>Режимы отображения</Components.Heading>
        <Components.Telematics />
        <Components.ConnectionDetectorGraph />
        <Components.Heading>Настройки расчетов</Components.Heading>
        <Components.Interval />
        <div className={consts.trait}></div>
        <Components.NumberOfEdges />
        <div className={consts.trait}></div>
        <Components.HashCommitVersion />
      </Components.ApplicationSettingsPopup>
      <SingleButton
        isActive={isPopupVisible}
        iconSvg={IconSVGName.settings}
        title="Панель управления"
        onClick={handleApplicationSettingsButtonClick}
        type={BUTTON_FORM.round}
      />
    </div>
  );
});
