import mapboxgl from "mapbox-gl";

/**
 * ISOCHRONE
 */
export const ISOCHRONE_FILL_ID = "isochrone-fill";
export const ISOCHRONE_STROKE_ID = "isochrone-stroke";
/**
 * TRANSPORT HUBS
 */
export const TRANSPORT_HUBS = "transport-hubs-layer";
export const TRANSPORT_HUBS_ICON = "transport-hubs-icon";
/**
 * TRAFFIC
 */
export const TRAFFIC_ID = "traffic-id";
export const TRAFFIC_ID_ARROW = "traffic-id-arrow";
/**
 * CORRELATION
 */
export const CORRELATION_HISTORY_ID = "correlation-traffic-historical";
export const CORRELATION_FORECAST_ID = "correlation-traffic-forecast";
/**
 * ROUTE
 */
export const ROUTE_PATH = "route-path";
export const ROUTE_PATH_STROKE = "route-path-stroke";
export const ROUTE_PATH_ALTERNATIVE = "route-path-alternative";
export const ROUTE_PATH_ALTERNATIVE_STROKE = "route-path-alternative-stroke";
export const ROUTE_INTERMEDIATE_POINT = "route-intermediate-point";
export const ROUTE_THROW_POINT = "route-move-throw-point";
export const ROUTE_TLS = "route-tls-layer";
export const ROUTE_BUS_TRANSIT = "route-bus-layer-transit";
export const ROUTE_BUS_FOOT = "route-bus-layer-foot";
export const ROUTE_METRO = "route-metro";
export const ROUTE_PEDESTRIAN = "route-pedestrian-layer";
export const ROUTE_VOLUMETRIC_DIAGRAM = "ROUTE_VOLUMETRIC_DIAGRAM";
export const ROUTE_SIMULATED_DTP_LAYER = "ROUTE_SIMULATED_DTP_LAYER";
export const ROUTE_SIMULATED_DTP_ICON = "ROUTE_SIMULATED_DTP_ICON";
/**
 * GRAPH
 */
export const GRAPH_EDGES_ID = "graph-edges-id";
export const GRAPH_VERTICES_ID = "graph-vertices-id";
export const GRAPH_EDGES_HIGHLIGHT_ID = "graph-edges-highlight-id";
export const GRAPH_VERTICES_HIGHLIGHT_ID = "graph-vertices-highlight-id";
export const GRAPH_SOURCE_ID = "graph-source-id";
export const GRAPH_ARROW_ID = "graph-arrow-id";
export const GRAPH_CARGO_EDGES_ID = "cargo-graph-id";
export const GRAPH_PEDESTRIAN = "pedestrian-graph-layer";
/**
 * TELEMETRY
 */
export const TELEMETRY_POINTS_ID = "points-id";
export const TELEMETRY_HEATMAP_ID = "heatmap-id";
export const TELEMETRY_BIND_TRACK_ID = "telemetry-track-id";
export const TELEMETRY_BIND_CONNECT_ID = "telemetry-bind-lines-id";
export const TELEMETRY_BIND_MATCHED = "telemetry-bind-matched";
export const TELEMETRY_BIND_LINE_ID = "telemetry-positions-line-id";
export const TELEMETRY_BIND_POINT_ID = "telemetry-positions-point-id";
export const TELEMETRY_BIND_UNMATCHED_LINE_ID = "telemetry-unmatched-track-id";
export const TELEMETRY_BIND_UNMATCHED_POINT_ID = "telemetry-unmatched-point-id";
/**
 * OVERLAP
 */
export const BLOCKING_POINTS_ID = "blocking-points-id";
export const LayerDash0 = "overlap-layer-dash-0";
export const LayerDash1 = "overlap-layer-dash-1";
export const LayerDash2 = "overlap-layer-dash-2";
export const LayerDash3 = "overlap-layer-dash-3";
export const LayerFull = "overlap-layer-full";
export const LayerIcon = "overlap-layer-icons";
export const TEMPORARY_BLOCKING_LAYER_ID = "TEMPORARY_BLOCKING_LAYER_ID";
/**
 * SPEEDCAM
 */
export const SPEEDCAM_LAYER_ID = "speedcam-layer-id";
export const SPEEDCAM_ACTIVE_LAYER_ID = `${SPEEDCAM_LAYER_ID}-active`;
export const SPEEDCAM_HOVER_LAYER_ID = `${SPEEDCAM_LAYER_ID}-active`;
export const SPEEDCAM_VOLUMETRIC = "speedcam-volumetric";
/**
 * CAMERA
 */
export const CAMERA_CODD_LAYER_ID = "camera-codd-layer-id";
export const CAMERA_CODD_ACTIVE_LAYER_ID = `${CAMERA_CODD_LAYER_ID}-active`;
export const CAMERA_CODD_HOVER_LAYER_ID = `${CAMERA_CODD_LAYER_ID}-hover`;
export const CAMERA_ECHD_LAYER_ID = "camera-echd-layer-id";
export const CAMERA_ECHD_ACTIVE_LAYER_ID = `${CAMERA_ECHD_LAYER_ID}-active`;
export const CAMERA_ECHD_HOVER_LAYER_ID = `${CAMERA_ECHD_LAYER_ID}-hover`;
/**
 * TLS
 */
export const TLS_ID = "tls-layer-id";
export const TLS_ACTIVE_ID = `${TLS_ID}-active`;
export const TLS_HOVER_ID = `${TLS_ID}-hover`;
/**
 * ROAD DETECTOR
 */
export const ROAD_DETECTOR_ID = "road-detector-id";
export const ROAD_DETECTOR_ACTIVE_ID = `${ROAD_DETECTOR_ID}-active`;
export const ROAD_DETECTOR_HOVER_ID = `${ROAD_DETECTOR_ID}-hover`;
/**
 * DETECTOR
 */
export const ND_DETECTOR_ID = "nd_detector-id";
export const DETECTOR_ID = "detector-id";
export const DETECTOR_ACTIVE_ID = `${DETECTOR_ID}-active`;
export const DETECTOR_HOVER_ID = `${DETECTOR_ID}-hover`;
export const DETECTOR_VOLUMETRIC = "detector-volumetric";
/**
 * OTHER
 */
export const BUS_STOP_ID = "bus-stop-id";
export const BUS_STOP_VOLUMETRIC = "bus-stop-volumetric-layer";
export const OVERLAP_ID = "overflap-layer";
export const OVERLAP_DASH_ID = "overflap-layer-dash";
export const DTP_ID = "dtp-id";
export const FLOODING_ID = "flooding-id";
export const WEATHER_FORECAST_ID = "weatherForeCast";
export const METEOSTATION_ID = "MAP_MS_CTRL__LAYER";
export const WEATHER_ID = "weather-id";
export const BAD_ROADS_ID = "bad-roads-id";
export const PLACE_LABEL_CITY = "place_label_city";
export const RAILWAY_SUBWAY = "railway-subway-min";
export const SECTOR_ANALYSIS_LAYER_ID = "sector-analysis-id";

/**
 * Districts
 */
export const DISTRICT_ID = "megapolis-dtm-district-layer";
export const DISTRICT_BORDER_ID = `${DISTRICT_ID}-border`;
export const DISTRICT_TITLE_ID = `${DISTRICT_ID}-title`;
export const DISTRICT_ZONE_ID = "megapolis-dtm-district-layer-zone";
export const DISTRICT_ZONE_BORDER_ID = `${DISTRICT_ZONE_ID}-border`;
export const DISTRICT_ZONE_TITLE_ID = `${DISTRICT_ZONE_ID}-title`;

export const CORRELATION_VOLUMETRIC = "correlation-volumetric";

const iconImageKeys = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

const order = [
  ISOCHRONE_FILL_ID,
  ISOCHRONE_STROKE_ID,
  GRAPH_SOURCE_ID,
  TEMPORARY_BLOCKING_LAYER_ID,
  TRAFFIC_ID,
  TRAFFIC_ID_ARROW,
  CORRELATION_HISTORY_ID,
  CORRELATION_FORECAST_ID,
  RAILWAY_SUBWAY,
  GRAPH_EDGES_ID,
  GRAPH_VERTICES_ID,
  GRAPH_EDGES_HIGHLIGHT_ID,
  GRAPH_VERTICES_HIGHLIGHT_ID,
  GRAPH_ARROW_ID,
  GRAPH_CARGO_EDGES_ID,
  GRAPH_PEDESTRIAN,
  TELEMETRY_HEATMAP_ID,
  TELEMETRY_POINTS_ID,
  TELEMETRY_BIND_TRACK_ID,
  TELEMETRY_BIND_CONNECT_ID,
  TELEMETRY_BIND_LINE_ID,
  TELEMETRY_BIND_UNMATCHED_LINE_ID,
  TELEMETRY_BIND_POINT_ID,
  TELEMETRY_BIND_UNMATCHED_POINT_ID,
  BUS_STOP_ID,
  TRANSPORT_HUBS,
  CAMERA_ECHD_LAYER_ID,
  DTP_ID,
  OVERLAP_ID,
  FLOODING_ID,
  OVERLAP_DASH_ID,
  BAD_ROADS_ID,
  LayerDash0,
  LayerDash1,
  LayerDash2,
  LayerDash3,
  LayerFull,
  LayerIcon,
  CAMERA_CODD_ACTIVE_LAYER_ID,
  CAMERA_CODD_HOVER_LAYER_ID,
  CAMERA_ECHD_ACTIVE_LAYER_ID,
  CAMERA_ECHD_HOVER_LAYER_ID,
  BLOCKING_POINTS_ID,
  ROUTE_PATH_ALTERNATIVE_STROKE,
  ROUTE_PATH_ALTERNATIVE,
  ROUTE_PATH_STROKE,
  ROUTE_PATH,
  ROUTE_TLS,
  ROUTE_BUS_TRANSIT,
  ROUTE_BUS_FOOT,
  ROUTE_METRO,
  ROUTE_PEDESTRIAN,
  ROUTE_INTERMEDIATE_POINT,
  ROUTE_SIMULATED_DTP_LAYER,
  ROUTE_VOLUMETRIC_DIAGRAM,
  SPEEDCAM_VOLUMETRIC,
  DETECTOR_VOLUMETRIC,
  CORRELATION_VOLUMETRIC,
  SPEEDCAM_ACTIVE_LAYER_ID,
  SPEEDCAM_HOVER_LAYER_ID,
  DETECTOR_HOVER_ID,
  PLACE_LABEL_CITY,
  ROAD_DETECTOR_ID,
  ND_DETECTOR_ID,
  TLS_ACTIVE_ID,
  TLS_HOVER_ID,
  TLS_ID,
  WEATHER_ID,
  SPEEDCAM_LAYER_ID,
  CAMERA_CODD_LAYER_ID,
  DETECTOR_ID,
  DETECTOR_ACTIVE_ID,
  SECTOR_ANALYSIS_LAYER_ID,
];

export function addRoutePointsToOrder(array: string[]) {
  for (let i = 0; i < 20; i++) array.push(`route-point-${iconImageKeys[i]}`);
}

const getActualListOrder = (order: string[], layers?: mapboxgl.AnyLayer[]) => {
  const layerIntermediatePoints = layers?.filter(
    ({ id }) =>
      id.startsWith(ROUTE_INTERMEDIATE_POINT) && id !== ROUTE_INTERMEDIATE_POINT && !order.some((keyId) => keyId === id)
  );
  return layerIntermediatePoints?.map(({ id }) => id);
};

export default function getBeforeId(id: string, map: mapboxgl.Map) {
  if (!map) return;

  if (!order.includes("route-point-A")) {
    addRoutePointsToOrder(order);
  }

  const layers = map.getStyle().layers;
  let listOrder = [...order];
  const actualLayers = getActualListOrder(order, layers);

  if (Array.isArray(actualLayers)) {
    listOrder = listOrder.concat(actualLayers);
  }

  let index = listOrder.indexOf(id);

  if (index < 0) return;

  const layersAfter = listOrder.slice(index + 1);
  const beforeId = layersAfter.find((el) => Boolean(map.getLayer(el)));

  return beforeId;
}
