import { LineDataType, DirectionDataType } from "features/ctrl-detector/types";
import React, { FC, useState } from "react";
import { GreenArrow } from "assets";
import {
  TitleBlock,
  TransportFlowBlock,
  TransportFlowItem,
  TransportFlowItemsBlock,
} from "../../detector-popup.styled";
import { DataBlock } from "../transport-flow-full-block/transport-flow-full-block.styled";
import { ValuesEdgesMiniBlock } from "./transport-flow-mini-block.styled";
import { Tag } from "components";
import { arrayFlowItemLabels } from "../consts";

type TransportFlowMiniBlockPropsType = {
  flowData?: Partial<LineDataType>;
  currentDirectionData?: DirectionDataType;
  persistentEdgeId: string | undefined;
  isLoad?: boolean;
  isUDSMode: boolean;
  selectedDetectorEdgeIds: string[];
};

export const TransportFlowMiniBlock: FC<TransportFlowMiniBlockPropsType> = (props) => {
  const [isShowFirstMiniBlock, setIsShowFirstMiniBlock] = useState(true);
  const [isShowSecondEdgesMiniBlock, setIsShowSecondEdgesMiniBlock] = useState(false);

  if (props.isLoad) {
    return null;
  }

  if (!props.flowData && !props.isLoad) {
    return (
      <TransportFlowBlock>
        <TitleBlock>
          <div className="title">Нет данных</div>
        </TitleBlock>
      </TransportFlowBlock>
    );
  }
  return (
    <TransportFlowBlock className="not-dragable-zone">
      <DataBlock>
        <TitleBlock>
          <div className="title">Параметры транспортного потока</div>
          <div onClick={() => setIsShowFirstMiniBlock(!isShowFirstMiniBlock)}>
            {isShowFirstMiniBlock ? <GreenArrow /> : <GreenArrow className="arrow-up" />}
          </div>
        </TitleBlock>
        {isShowFirstMiniBlock && (
          <TransportFlowItemsBlock>
            {arrayFlowItemLabels.map((item) => (
              <TransportFlowItem key={item.name}>
                <div className="label">
                  {item.label}
                  {item.name === "avgVolume" || item.name === "avgDensity" ? (
                    <Tag title="Приведённая к легковым ТС" className="transport-flow-mini-block-tag">
                      легк.
                    </Tag>
                  ) : (
                    <div />
                  )}
                </div>
                <div className="value">{props.flowData?.[item.name]}</div>
              </TransportFlowItem>
            ))}
          </TransportFlowItemsBlock>
        )}
      </DataBlock>
      {props.selectedDetectorEdgeIds && !props.isUDSMode && props.selectedDetectorEdgeIds.length > 0 && (
        <DataBlock>
          <TitleBlock>
            <div className="title">Рёбра {props.selectedDetectorEdgeIds.length}</div>
            <div onClick={() => setIsShowSecondEdgesMiniBlock(!isShowSecondEdgesMiniBlock)}>
              {isShowSecondEdgesMiniBlock ? <GreenArrow /> : <GreenArrow className="arrow-up" />}
            </div>
          </TitleBlock>
          {isShowSecondEdgesMiniBlock && (
            <ValuesEdgesMiniBlock className="value">{props.selectedDetectorEdgeIds.join(", ")}</ValuesEdgesMiniBlock>
          )}
        </DataBlock>
      )}
    </TransportFlowBlock>
  );
};
