import React from "react";
import mapboxgl, { LngLat } from "mapbox-gl";
import { MiddlewareAPI } from "redux";
import moment from "moment/moment";
import _ from "lodash";
import { GState } from "documentations";
import { distinctItems } from "utils";
import { ROAD_DETECTOR_ID } from "map-helpers/order-layers";
import { popupController } from "map-helpers/popups/popup-controller";
import { loadVCParamSelector } from "../store/selectors";
import { CreatePopupProps } from "../types";
import { RoadDetectorLayer } from "../map-layer/road-detector-layer";
import { DetectorPopup } from "../../ctrl-detector";
import { getMergedTraffic, getRoadDetectors } from "../../../api/detector-data/detector-data";
import { APP_ENV } from "../../../app-env";
import { LineDataType } from "../../ctrl-detector/types";
import { MergedTrafficResponse } from "api/detector-data/detector-data.types";
import { preciseNumber } from "utils/precise-number";

type ArrayValues<Keys extends string> = {
  [Property in Keys]: number[];
};

type LaneData = { laneId: number } & LineDataType;

export class RoadDetectorController {
  private roadDetectorLayer: RoadDetectorLayer;
  private activeItems: number[] = [];
  private from: string = "";
  private to: string = "";

  constructor(private map: mapboxgl.Map, private store: MiddlewareAPI<any, GState>) {
    this.roadDetectorLayer = new RoadDetectorLayer(map, ROAD_DETECTOR_ID, ROAD_DETECTOR_ID);
    map.on("mousemove", this.roadDetectorLayer.layerId, () => {
      map.getCanvas().style.cursor = "pointer";
    });
    this.roadDetectorLayer.on("click", this.handleClick);
    this.map.on("style.load", () => {
      this.update();
    });
    this.update();
  }

  public update = () => {
    const {
      roadDetector: { isActive },
    } = this.store.getState();

    if (isActive) this.addLayer();
    else this.removeLayer();
  };

  private handleClick = (e: mapboxgl.MapMouseEvent, features: mapboxgl.MapboxGeoJSONFeature[]) => {
    const feature = features.shift();
    if (!feature) return;
    const {
      geometry: {
        // @ts-ignore
        coordinates,
      },
      // @ts-ignore
      properties: { detectors, pid, address, startPid, detectorId: id },
    } = feature;

    let parsedDetectors = id ? [id, id] : [];
    try {
      if (detectors) {
        parsedDetectors = JSON.parse(detectors);
      }
    } catch {
      console.error(detectors);
      parsedDetectors = [];
    }
    const [Lng, Lat] = coordinates[0];
    if (!parsedDetectors?.length || !Lat || !Lng) return;
    const lngLat = new LngLat(Lng, Lat);
    const detectorId = parsedDetectors[0];
    if (!detectorId) return;

    this.createPopup({ detectorId, lngLat, pid, address, detectors: parsedDetectors, startPid });
    this.setActiveItems(detectorId);
  };

  private setActiveItems(id: number | null) {
    const nullFilter: mapboxgl.Expression = ["boolean", false];
    if (typeof id !== "number") {
      this.activeItems = [];
      this.roadDetectorLayer.setFilterOnActiveLayer(nullFilter);
      return;
    }
    if (this.activeItems.includes(id)) this.activeItems = this.activeItems.filter((el) => el !== id);
    else this.activeItems.push(id);

    if (!this.activeItems.length) {
      this.roadDetectorLayer.setFilterOnActiveLayer(nullFilter);
      return;
    }
  }

  private async getDetectorData() {
    const {
      traffic: { type },
      view: { from: storeFrom, to: storeTo },
    } = this.store.getState();

    const activeFilters = loadVCParamSelector(this.store.getState());

    const period = APP_ENV?.REACT_APP_CONFIGURATION?.trafficLastPeriod ?? 20;
    const currentDate = moment();
    const currentMinutes = currentDate.minutes();
    const rest = currentMinutes % 5;
    const formatedFrom = moment()
      .add(-rest - 5 - period, "minutes")
      .format("YYYY-MM-DD HH:mm")
      .split(" ");
    const formatedTo = moment()
      .add(-rest - 5, "minutes")
      .format("YYYY-MM-DD HH:mm")
      .split(" ");
    const fromWithoutMill = `${formatedFrom[0]}T${formatedFrom[1]}`;
    const toWithoutMill = `${formatedTo[0]}T${formatedTo[1]}`;

    if (type === "last") {
      this.from = fromWithoutMill;
      this.to = toWithoutMill;
    } else {
      const [from, to] = [storeFrom, storeTo].map((x) => moment(x).format("YYYY-MM-DDTHH:mm"));
      this.from = from;
      this.to = to;
    }

    const params: Parameters<typeof getRoadDetectors>[0] = { from: this.from, to: this.to };
    if (activeFilters) {
      params.vc = activeFilters;
    }

    const response = await getRoadDetectors(params);
    return response.data;
  }

  private async addLayer() {
    const data = await this.getDetectorData();
    this.roadDetectorLayer.add(data);
  }

  private removeLayer() {
    this.roadDetectorLayer.remove();
    popupController?.removePopupByType("roadDetector");
    this.setActiveItems(null);
  }

  private trafficDataAdapter = (
    data?: MergedTrafficResponse
  ): React.ComponentProps<typeof DetectorPopup>["detectorData"] => {
    if (!data) return;

    const initial: ArrayValues<Exclude<keyof LineDataType, "from" | "to">> & {
      lines: LaneData[];
    } = {
      avgDensity: [],
      avgOcc: [],
      avgSpeed: [],
      avgVolume: [],
      avgUtilization: [],
      avgFreewaySpeed: [],
      totalUnitsCount: [],
      lines: [],
    };

    const { Traffic, Pid } = data;
    const dates = new Set<number>();

    const setDate = (from: string) => {
      const fromDate = new Date(from);
      dates.add(fromDate.getTime());
    };

    const getLaneData = (traffic: typeof Traffic[0]): LaneData => ({
      avgDensity: traffic.Density,
      avgOcc: traffic.Occ,
      avgSpeed: traffic.Speed,
      avgVolume: traffic.Volume,
      avgUtilization: traffic.Utilization,
      avgFreewaySpeed: traffic.FreewaySpeed,
      totalUnitsCount: traffic.Quantity,
      from: traffic.From,
      to: traffic.To,
      laneId: traffic.LaneId,
    });

    const values = Traffic.reduce<ArrayValues<Exclude<keyof LineDataType, "from" | "to">> & { lines: LaneData[] }>(
      (result, item) => {
        setDate(item.From);
        setDate(item.To);
        result.avgOcc.push(item.Occ);
        result.avgDensity.push(item.Density);
        result.avgSpeed.push(item.Speed);
        result.avgVolume.push(item.Volume);
        result.avgUtilization.push(item.Utilization);
        result.avgFreewaySpeed.push(item.FreewaySpeed);
        result.totalUnitsCount.push(item.Quantity);
        result.lines.push(getLaneData(item));

        return result;
      },
      initial
    );

    const fromDate = () => {
      const min = _.min(Array.from(dates.values()));
      return new Date(min ?? 0).toISOString();
    };

    const toDate = () => {
      const max = _.max(Array.from(dates.values()));
      return new Date(max ?? 0).toISOString();
    };

    const getMean = (values: number[]) => {
      return preciseNumber(_.mean(values));
    };

    return {
      direction: "forward",
      isLoad: false,
      forward: {
        persistentEdgeId: String(Pid),
        flowData: {
          avgDataAllLines: {
            avgDensity: getMean(values.avgDensity),
            avgOcc: getMean(values.avgOcc),
            avgSpeed: getMean(values.avgSpeed),
            avgVolume: getMean(values.avgVolume),
            avgUtilization: getMean(values.avgUtilization),
            avgFreewaySpeed: getMean(values.avgFreewaySpeed),
            totalUnitsCount: _.sum(values.totalUnitsCount),
            to: toDate(),
            from: fromDate(),
          },
          lines: values.lines,
        },
      },
    };
  };

  private createPopup({
    detectorId,
    lngLat,
    pid,
    address,
    detectors,
    startPid,
  }: CreatePopupProps & { detectors: number[]; startPid: string }) {
    const containerId = `detector-popup-${detectorId}`;
    popupController?.removePopupById(containerId);
    const onClickClose = (e: React.MouseEvent<HTMLButtonElement | SVGSVGElement, MouseEvent>) => {
      if (e.ctrlKey) {
        this.setActiveItems(null);
      } else {
        popupController?.removePopupById(containerId);
        this.setActiveItems(detectorId);
      }
    };

    getMergedTraffic({
      detectorIds: detectors,
      startPid,
      pid,
      from: this.from,
      to: this.to,
    }).then((r) => {
      const detectorData = this.trafficDataAdapter(r.data[0]);

      const showGreyIcon: boolean = true;

      const component = (
        <DetectorPopup
          id={distinctItems(detectors.map(String))}
          num={"num"}
          address={address}
          onClickClose={onClickClose}
          persistentEdgeId={pid}
          detectorData={detectorData}
          showGreyIcon={showGreyIcon}
        />
      );
      popupController?.addPopup({ component, lngLat, id: containerId, type: "roadDetector", itemId: detectorId });
    });
  }
}
