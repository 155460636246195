import React from "react";
import { Checkbox } from "components";
import * as hooks from "./connection-detector-graph.hooks";
import "./connection-detector-graph.scss";

export const ConnectionDetectorGraph = React.memo(() => {
  const logicData = hooks.useIsShowConnectionLogic();

  return (
    <div>
      <Checkbox
        label="Отображение связи детекторов и ребер графа"
        value=""
        title="Отображение связи детекторов и ребер графа"
        checked={logicData.value}
        onChange={logicData.handleChange}
      />
    </div>
  );
});
