import { useStoreContext } from "store";

export const useIsShowConnectionLogic = () => {
  const { store } = useStoreContext();
  const value = store.applicationSettings.hooks.useIsShowConnection();

  return {
    value,
    handleChange: store.applicationSettings.events.handleConnectionDetectorGraph,
  };
};
