import React from "react";
import { APP_ENV } from "app-env";
import { Checkbox } from "components";
import * as hooks from "./telematics.hooks";
import * as consts from "./telematics.consts";
import "./telematics.scss";

export const Telematics = React.memo(() => {
  const logicData = hooks.useIsEmptyPiecesLogic();

  if (APP_ENV.REACT_APP_CONFIGURATION?.settings?.telematics !== "on") return null;

  return (
    <div className={consts.baseClass}>
      <Checkbox
        label="Участки с отсутствующей телематикой"
        value=""
        title="Участки с отсутствующей телематикой"
        checked={logicData.value}
        onChange={logicData.handleChange}
      />
    </div>
  );
});
