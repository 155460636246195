import React from "react";
import { createEvent } from "effector";
import { State } from "./application-settings.types";

/** События UI */
const handleOutsideClick = createEvent();
const handleActiveMapStyleChange = createEvent<string | number>();
const handleTelematicsIsEmptyPieces = createEvent<React.SyntheticEvent<HTMLInputElement>>();
const handleConnectionDetectorGraph = createEvent<React.SyntheticEvent<HTMLInputElement>>();
const handleShowInterval = createEvent<React.SyntheticEvent<HTMLInputElement>>();
const handleApplicationSettingsButtonClick = createEvent<React.MouseEventHandler<HTMLButtonElement>>();
const handleNumberOfEdges = createEvent<number>();

/** Доставщики информации в брокер */
const isEmptyPiecesDeliveryman = createEvent<State>();
const isShowConnectionDeliveryman = createEvent<State>();
const isShowIntervalDeliveryman = createEvent<State>();
const isNumberOfEdgesDeliveryman = createEvent<State>();
const activeMapStyleDeliveryman = createEvent<State>();

export const events = {
  /** События UI */
  handleOutsideClick,
  handleActiveMapStyleChange,
  handleTelematicsIsEmptyPieces,
  handleConnectionDetectorGraph,
  handleShowInterval,
  handleApplicationSettingsButtonClick,
  handleNumberOfEdges,
  /** Доставщики информации в брокер */
  isShowConnectionDeliveryman,
  isEmptyPiecesDeliveryman,
  isShowIntervalDeliveryman,
  activeMapStyleDeliveryman,
  isNumberOfEdgesDeliveryman,
};

export type Events = typeof events;
