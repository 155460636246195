export type HistoryTravelState = {
  trafficFlowParams: FlowParamsType[];
  detectorRoadFlowParams: FlowParamsType[];
  detectorScores: Pick<FlowParamsType, "scoreMSG" | "score" | "time">[];
  rollUp: boolean;
};

export type FlowParamsType = {
  time: string;
  type: string;
  averageSpeed: string;
  freeFlowAverageSpeed: string;
  averageOcc: string;
  averageUtilization: string;
  averageDensity: string;
  averageVolume: string;
  score?: string;
  alternativeScore?: string;
  alternativeScoreMSG?: string;
  scoreMSG?: string;
};

export enum HistoryTravelLabelType {
  AverageSpeed = "Ср.скор.",
  Density = "Плотн.",
  Intensity = "Интенс.",
  OccupiedRoad = "Занят.дор.",
  IDZ = "ИДЗ",
  Traffic = "Пробки",
}
