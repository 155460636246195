import React, { useEffect, useState } from "react";
import * as hooks from "./number-of-edges.hooks";
import * as consts from "./number-of-edges.consts";
import { ReactComponent as IconMin } from "./minus.svg";
import { ReactComponent as IconPlus } from "./plus.svg";
import "./number-of-edges.scss";

export const NumberOfEdges = React.memo(() => {
  const logicData = hooks.useIsNumberOfEdgesLogic();
  const [cur, setCur] = useState(logicData.value);

  useEffect(() => {}, [cur]);

  const _handleChange = (val: number) => {
    if (val < 0 || val > 10) return;
    setCur(val);
    logicData.handleChange(val);
  };

  return (
    <div className={consts.baseClassContainer}>
      <div className={consts.baseClass}>
        <div>{cur === 10 ? "Макс." : cur}</div>
        <div className={consts.iconBlock}>
          <IconMin onClick={() => _handleChange(cur - 1)} />
          <IconPlus onClick={() => _handleChange(cur + 1)} />
        </div>
      </div>
      <div className={consts.decription}>Кол-во рёбер для подсчета ПТП</div>
    </div>
  );
});
