import { DirectionDataType } from "features/ctrl-detector/types";
import React, { FC, useState } from "react";
import { TitleBlock } from "../../detector-popup.styled";
import { GreenArrow } from "assets";
import { useGetArrayLines, useGetStatisticData } from "./hooks";
import {
  AvgFlowDataBlock,
  DataBlock,
  Row,
  RowsBlock,
  TransportFlowBlock,
  ValuesBlock,
  ValuesEdgesBlock,
  TransportFlowItem,
} from "./transport-flow-full-block.styled";
import { Tag } from "components";
import { DetectorGraph } from "../detector-graph";
import { arrayFlowItemLabels } from "../consts";

type TransportFlowBlockPropsType = {
  tileData: { id: string; address: string; num: string };
  currentDirectionData?: DirectionDataType;
  isUDSMode: boolean;
  selectedDetectorEdgeIds: string[];
};

export const TransportFlowFullBlock: FC<TransportFlowBlockPropsType> = (props) => {
  const { avgDataAllLines, lines } = props.currentDirectionData?.flowData || {};

  const [isShowFirstBlock, setIsShowFirstBlock] = useState(true);
  const [isShowSecondBlock, setIsShowSecondBlock] = useState(true);
  const [isShowSecondEdgesBlock, setIsShowSecondEdgesBlock] = useState(false);
  const [isShowThirdBlock, setIsShowThirdBlock] = useState(true);

  const linesArraysObject = useGetArrayLines(lines);

  const graphData = useGetStatisticData(props.currentDirectionData?.flowDataLastDayStat);

  const utilY = {
    stepSize: 1,
  };
  return (
    <TransportFlowBlock className="not-dragable-zone">
      <DataBlock>
        <TitleBlock>
          <div className="title">Параметры транспортного потока</div>
          <div onClick={() => setIsShowFirstBlock(!isShowFirstBlock)}>
            {isShowFirstBlock ? <GreenArrow /> : <GreenArrow className="arrow-up" />}
          </div>
        </TitleBlock>
        {isShowFirstBlock && (
          <AvgFlowDataBlock>
            {arrayFlowItemLabels.map((item) => (
              <TransportFlowItem key={item.name}>
                <div className="label">
                  {item.label}
                  {item.name === "avgVolume" || item.name === "avgDensity" ? (
                    <Tag title="Приведённая к легковым ТС" className="transport-flow-parameters-tag">
                      легк.
                    </Tag>
                  ) : (
                    <div></div>
                  )}
                </div>
                <div className="value">{avgDataAllLines?.[item.name]}</div>
              </TransportFlowItem>
            ))}
          </AvgFlowDataBlock>
        )}
      </DataBlock>
      <DataBlock>
        <TitleBlock>
          <div className="title">Параметры по полосам</div>
          <div onClick={() => setIsShowSecondBlock(!isShowSecondBlock)}>
            {isShowSecondBlock ? <GreenArrow /> : <GreenArrow className="arrow-up" />}
          </div>
        </TitleBlock>
        {isShowSecondBlock && (
          <RowsBlock>
            <Row isNotBorderBottom>
              <div />
              <ValuesBlock>
                {Number(lines?.length) > 1 && <div className="label value">Общ.</div>}
                {lines?.map((_, i) => (
                  <div className="label value">{i + 1}П</div>
                ))}
              </ValuesBlock>
            </Row>

            {arrayFlowItemLabels.map((item, index) => (
              <Row isNotBorderBottom={index + 1 === arrayFlowItemLabels.length} key={item.name}>
                <div className="label">
                  {item.label}
                  {item.name === "avgVolume" || item.name === "avgDensity" ? (
                    <Tag title="Приведённая к легковым ТС" className="lanes-parameters-tag">
                      легк.
                    </Tag>
                  ) : (
                    <div></div>
                  )}
                </div>
                <ValuesBlock>
                  <div className="value">{avgDataAllLines?.[item.name]}</div>
                  {linesArraysObject[item.name].map((value, index) => {
                    if (Number(linesArraysObject[item.name].length) === 1 && index === 0) {
                      return "";
                    }
                    return (
                      <div className="value" key={String(value) + index}>
                        {value}
                      </div>
                    );
                  })}
                </ValuesBlock>
              </Row>
            ))}
          </RowsBlock>
        )}
      </DataBlock>
      {props.selectedDetectorEdgeIds && !props.isUDSMode && props.selectedDetectorEdgeIds.length > 0 && (
        <DataBlock>
          <TitleBlock>
            <div className="title">Рёбра {props.selectedDetectorEdgeIds.length}</div>
            <div onClick={() => setIsShowSecondEdgesBlock(!isShowSecondEdgesBlock)}>
              {isShowSecondEdgesBlock ? <GreenArrow /> : <GreenArrow className="arrow-up" />}
            </div>
          </TitleBlock>
          {isShowSecondEdgesBlock && (
            <ValuesEdgesBlock className="value">{props.selectedDetectorEdgeIds.join(", ")}</ValuesEdgesBlock>
          )}
        </DataBlock>
      )}
      {!props.isUDSMode && graphData && (
        <DataBlock>
          <TitleBlock>
            <div className="title">Статистика за 24 часа</div>
            <div onClick={() => setIsShowThirdBlock(!isShowThirdBlock)}>
              {isShowThirdBlock ? <GreenArrow /> : <GreenArrow className="arrow-up" />}
            </div>
          </TitleBlock>
          {isShowThirdBlock && (
            <>
              <TitleBlock>
                <div className="title-light">Средняя скорость и скорость свободной дороги</div>
              </TitleBlock>
              <DetectorGraph data={graphData.speed} />
              <TitleBlock>
                <div className="title-light">Занятость дороги</div>
              </TitleBlock>
              <DetectorGraph data={graphData.occ} />
              <TitleBlock>
                <div className="title-light">
                  Интенсивность потока
                  <Tag title="Приведённая к легковым ТС" className="statistics-parameters-tag">
                    легк.
                  </Tag>
                </div>
              </TitleBlock>
              <DetectorGraph data={graphData.volume} />
              <TitleBlock>
                <div className="title-light">Загруженность</div>
              </TitleBlock>
              <DetectorGraph data={graphData.utilization} y={utilY} />
              <TitleBlock>
                <div className="title-light">
                  Плотность потока
                  <Tag title="Приведённая к легковым ТС" className="statistics-parameters-tag">
                    легк.
                  </Tag>
                </div>
              </TitleBlock>
              <DetectorGraph data={graphData.density} />
              <TitleBlock>
                <div className="title-light">Количество ТС</div>
              </TitleBlock>
              <DetectorGraph data={graphData.totalUnitsCount} />
            </>
          )}
        </DataBlock>
      )}
    </TransportFlowBlock>
  );
};
