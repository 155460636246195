import styled, { css } from "styled-components";
import { GreenArrow } from "../../../assets";

export const PopupContainer = styled.div`
  background-color: white;
  border-radius: 6px;
  position: absolute;
  top: 75px;
  left: 15px;
  width: 780px;
  height: 630px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.161);

  &.history-travel-popup-custom-height {
    height: 390px;
  }
`;

export const RotatableGreenArrow = styled(GreenArrow)`
  transition: transform 0.3s ease;

  &.rotated {
    transform: rotate(-90deg);
  }
`;

export const PopupHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
  padding: 16px 20px;
`;

export const HeaderTitle = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: rgba(33, 33, 33, 0.66);
  font-weight: bold;
`;

export const PopupBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 18px 20px;
`;

export const BodyTitle = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: #212121;
  margin-top: 16px;
  font-weight: bold;

  &.history-travel-popup {
    margin-top: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
  }
`;

export const BodyTable = styled.div`
  display: flex;
  flex-direction: column;

  &.history-travel-popup-info {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const Label = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
  font-size: 12px;
  line-height: 16px;
  color: #747474;
  margin: 3px 0;
`;

export const Message = styled.div`
  color: #212121;
  font-size: 12px;
  line-height: 16px;
`;

export const Row = styled.div<{ withBorder?: boolean }>`
  display: grid;
  grid-template-columns: 250px 140px 140px 140px;
  border-bottom: ${(props) => (props.withBorder ? "1px solid #E0E0E0" : "none")};
  min-height: 32px;

  .history-travel-popup-tag {
    margin-left: 6px;
  }

  &.history-travel-popup-info {
    display: flex;
    gap: 3px;
  }

  &.history-travel-popup-custom-grid {
    grid-template-columns: 1fr 140px 140px 140px;
  }
`;

export const Cell = styled.div<{ isFuture?: boolean }>`
  ${(props) =>
    props.isFuture
      ? css`
          background-color: #f5f5f5;
          color: rgba(33, 33, 33, 0.66);
        `
      : css`
          color: #080808;
        `};
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  position: relative;

  &.cell-history-travel-popup {
    color: #000000;
    background-color: #ffffff;
    width: auto;
    gap: 2px;
  }

  &.cell-history-travel-popup-custom-color {
    color: #747474;
  }
`;

export const StatusBlock = styled.div<{ left?: number }>`
  position: absolute;
  left: ${(props) => (props.left ? props.left + "%" : "55%")};
  width: 13px;
`;
