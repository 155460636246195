import styled from "styled-components";

export const ValuesEdgesMiniBlock = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 9px;
  width: 100%;
  font-size: 12px;
  .value {
    display: flex;
    justify-content: flex-start;
    font-size: 12px;
  }
`;
