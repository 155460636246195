import { useStore } from "effector-react";
import { store } from "./application-settings";

const useIsPopupVisible = () => useStore(store).isPopupVisible;
const useIsEmptyPieces = () => useStore(store).telematics.isEmptyPieces;
const useIsShowConnection = () => useStore(store).connectionDetectorGraph.isShowConnection;
const useIsShowInterval = () => useStore(store).interval.isShowInterval;
const useIsNumberOfEdges = () => useStore(store).numberOfEdges.value;
const useActiveMapStyle = () => useStore(store).activeMapStyle;

export const hooks = {
  useIsPopupVisible,
  useIsEmptyPieces,
  useActiveMapStyle,
  useIsShowConnection,
  useIsShowInterval,
  useIsNumberOfEdges,
};

export type Hooks = typeof hooks;
