import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GState } from "documentations";
import { Exit, ExitFullArrows, FullArrows } from "assets";
import { Loader } from "components/loader";
import { RoundToggleButton } from "components";
import { DirectionDataType, DirectionType } from "../../types";
import { detectorSlice, loadLastDayDetectorData } from "../../store/slice";
import { getEdgesIdsByDetector } from "api/detector-data";
import { ButtonsBlock, FirstBlock, HeaderBlock, PopupContainer, ToggleButtonBlock } from "./detector-popup.styled";
import { TransportFlowMiniBlock } from "./components/transport-flow-mini-block";
import { TransportFlowFullBlock } from "./components/transport-flow-full-block";
import { DetectorHeader, UdsHeader } from "./components/detector-header";
import { DetectorIsTimeOverAlert } from "./components/detector-alert";

type Props = {
  id: string | string[];
  num: string;
  address?: string;
  onClickClose: (e: React.MouseEvent<HTMLButtonElement | SVGSVGElement, MouseEvent>) => void;
  token?: string;
  persistentEdgeId?: string;
  lastTime?: string;
  showGreyIcon: boolean;
  detectorData?: {
    forward?: DirectionDataType | undefined;
    backward?: DirectionDataType | undefined;
    isLoad: boolean;
    direction: DirectionType;
  };
};

type TileData = Required<Pick<Props, "address" | "num" | "detectorData">> &
  ({ isUDSMode: true; id: string[] } | { isUDSMode: false; id: string; showGreyIcon: boolean });

export const DetectorPopup: React.FC<Props> = ({
  id,
  num,
  address = "address",
  onClickClose,
  persistentEdgeId,
  detectorData,
  lastTime,
  showGreyIcon,
}) => {
  const dispatch = useDispatch();

  const [isFullMode, setIsFullMode] = useState(false);

  const [selectedDetectorEdgeIds, setSelectedDetectorEdgeIds] = useState<string[]>([]);

  const isUDSMode = Boolean(persistentEdgeId);

  const tileData = useMemo(
    () => ({ isUDSMode, id, num, address, detectorData }),
    [isUDSMode, id, num, address, detectorData]
  ) as TileData;

  const fetchEdgesData = useCallback(async () => {
    const result = await getEdgesIdsByDetector(Number(tileData.id));
    setSelectedDetectorEdgeIds(result.data);
  }, [tileData.id]);

  useEffect(() => {
    if (!tileData.isUDSMode) {
      fetchEdgesData();
    }
  }, [tileData]);

  const toggleIsFullMode = () => setIsFullMode(!isFullMode);
  const detectorStore = useSelector((state: GState) => {
    if (!tileData.isUDSMode) {
      return state.detector.openedDetectors[tileData.id];
    }
  });

  const { forward, isLoad, direction = "forward", backward } = isUDSMode ? detectorData || {} : detectorStore ?? {};

  const buttonsValues: { value: typeof direction; caption: string }[] = [
    {
      value: "forward",
      caption: "Прямое напр.",
    },
    {
      value: "backward",
      caption: "Обратное напр.",
    },
  ];

  const setActiveDirection = (value: string) => {
    const directionAs = value as typeof direction;
    if (!tileData.isUDSMode) {
      dispatch(detectorSlice.actions.setDirection({ detectorId: tileData.id, direction: directionAs }));
    }
  };

  useEffect(() => {
    if (isFullMode && !tileData.isUDSMode) {
      !forward?.flowDataLastDayStat && dispatch(loadLastDayDetectorData(tileData));
    }
  }, [isFullMode, isUDSMode, forward?.flowDataLastDayStat]);

  const currentData = useMemo(() => {
    if (persistentEdgeId) {
      /**
       * fixationlanes запрашивается с идентификатором детектора,
       * метод возвращает первое ребро в треке привязанного детектора,
       * а не на которое был сделан клик
       * */
      return [forward, backward].find(Boolean);
    } else {
      return direction === "forward" ? forward : backward;
    }
  }, [persistentEdgeId, forward, backward, direction]);

  return (
    <PopupContainer isFullMode={isFullMode}>
      <HeaderBlock>
        {tileData.isUDSMode ? (
          <UdsHeader isFullMode={isFullMode} tileData={tileData} />
        ) : (
          <DetectorHeader isFullMode={isFullMode} tileData={tileData} lastTime={lastTime} showGreyIcon={showGreyIcon} />
        )}
        <ButtonsBlock className="not-dragable-zone">
          <FirstBlock>
            {forward ? (
              isFullMode ? (
                <ExitFullArrows onClick={toggleIsFullMode} />
              ) : (
                <FullArrows onClick={toggleIsFullMode} />
              )
            ) : (
              <div />
            )}
            {isLoad && <Loader />}
          </FirstBlock>
          <Exit onClick={onClickClose} />
        </ButtonsBlock>
      </HeaderBlock>
      {!isUDSMode && <DetectorIsTimeOverAlert lastTime={lastTime} />}
      {backward && !isUDSMode && (
        <ToggleButtonBlock className="not-dragable-zone">
          <RoundToggleButton activeValue={direction} values={buttonsValues} setActive={setActiveDirection} />
        </ToggleButtonBlock>
      )}
      {isFullMode ? (
        <TransportFlowFullBlock
          selectedDetectorEdgeIds={selectedDetectorEdgeIds}
          currentDirectionData={currentData}
          isUDSMode={isUDSMode}
          tileData={{ ...tileData, id: String(tileData.id) }}
        />
      ) : (
        <TransportFlowMiniBlock
          selectedDetectorEdgeIds={selectedDetectorEdgeIds}
          currentDirectionData={currentData}
          persistentEdgeId={persistentEdgeId}
          isUDSMode={isUDSMode}
          isLoad={isLoad}
          flowData={currentData?.flowData?.avgDataAllLines}
        />
      )}
    </PopupContainer>
  );
};
