import { sample } from "effector";
import { store } from "./application-settings";
import { events } from "./application-settings.events";

/** Создание доставки значения отображения пустых кусков телематики в брокер */
const createIsEmptyPiecesDelivery = () => {
  sample({
    source: store,
    clock: events.handleTelematicsIsEmptyPieces,
    target: events.isEmptyPiecesDeliveryman,
  });
};

/** Создание доставки значения отображения взаимосвяз  детектора и ребра */
const createIsShowConnectionDelivery = () => {
  sample({
    source: store,
    clock: events.handleConnectionDetectorGraph,
    target: events.isShowConnectionDeliveryman,
  });
};

/** Создание доставки значения отображения интевала */
const createIsShowIntervalDelivery = () => {
  sample({
    source: store,
    clock: events.handleShowInterval,
    target: events.isShowIntervalDeliveryman,
  });
};

/** Создание доставки значения активного стиля карты в брокер */
const createActiveMapStyleDelivery = () => {
  sample({
    source: store,
    clock: events.handleActiveMapStyleChange,
    target: events.activeMapStyleDeliveryman,
  });
};

/** Фабрика по созданию доставок */
export const deliveriesFactory = () => {
  createIsEmptyPiecesDelivery();
  createActiveMapStyleDelivery();
  createIsShowConnectionDelivery();
  createIsShowIntervalDelivery();
};
