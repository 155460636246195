import { useStoreContext } from "store";

export const useIsShowIntervalLogic = () => {
  const { store } = useStoreContext();
  const value = store.applicationSettings.hooks.useIsShowInterval();

  return {
    value,
    handleChange: store.applicationSettings.events.handleShowInterval,
  };
};
