import React, { useState, useEffect } from "react";
import { APP_ENV } from "app-env";
import * as consts from "./hash-commit-version.consts";
import "./hash-commit-version.scss";

export const HashCommitVersion = React.memo(() => {
  const [versionBackend, setVersionBackend] = useState("");
  const slicedVersionFrontend = APP_ENV.REACT_APP_V_FE.split(" ");

  useEffect(() => {
    const fetchVersionBackend = async () => {
      const response = await fetch(`${APP_ENV.REACT_APP_DTM_ROUTER_API}/about/version`);
      const data = await response.json();
      setVersionBackend(`${data.tag} (${data.commitHash})`);
    };
    fetchVersionBackend();
  }, []);

  return (
    <div className={consts.baseClass}>
      <div className="after-trait-text-version">Версия</div>
      <div className="after-trait-text-version-block">
        <div className="after-trait-text">
          FE {slicedVersionFrontend[0]} ({slicedVersionFrontend[1]})
        </div>
        <div className="after-trait-text">BE {versionBackend}</div>
      </div>
    </div>
  );
});
