/* eslint-disable prefer-const */
import moment from "moment";
import { APP_ENV } from "app-env";
import { TrafficAPI } from "api/traffic";
import { TrafficTileFilter } from "api/traffic/model/tiles";
import { TrafficData, TrafficPeriod } from "api/traffic/model/traffic";
import getBeforeId, { TRAFFIC_ID } from "../order-layers";
import TrafficProperties from "../properties/traffic-properties";
import Layer from "./layer";
import TrafficArrowLayer from "./traffic-arrow-layer";
import { hideCurrentTrafficLayer } from "features/view-data/map-helpers";

const updateTime = 300000;
const layerId = TRAFFIC_ID;

export default class TrafficLayer extends Layer {
  updateTimeout;
  isEmptyPieces = true;

  constructor(map) {
    super(map, layerId);
    this.map = map;
    this.layerId = TRAFFIC_ID;
    this.properties = new TrafficProperties();
    this.arrowLayer = new TrafficArrowLayer(map);
  }

  getUrl() {
    let { es, filter, type, from, to } = this.data;
    const input = { es };
    const period = APP_ENV?.REACT_APP_CONFIGURATION?.trafficLastPeriod ?? 20;

    if (filter === "highway") input.filter = TrafficTileFilter.Highway;
    if (filter === "trans") input.filter = TrafficTileFilter.Transport;
    if (filter === "dedicated") input.filter = TrafficTileFilter.DedicatedLines;

    if (type === TrafficPeriod.Last) {
      const currentDate = moment();
      const currentMinutes = currentDate.minutes();
      const rest = currentMinutes % 5;
      const from = moment().add("minutes", -rest - 5 - period);
      const to = moment().add("minutes", -rest - 5);
      const formatedFrom = from.format("YYYY-MM-DD HH:mm").split(" ");
      const formatedTo = to.format("YYYY-MM-DD HH:mm").split(" ");

      try {
        const fromWithoutMill = `${formatedFrom[0]}T${formatedFrom[1]}`;
        const toWithoutMill = `${formatedTo[0]}T${formatedTo[1]}`;

        input.from = fromWithoutMill;
        input.to = toWithoutMill;
      } catch {}
    } else {
      input.from = from;
      input.to = to;
    }

    if (moment(to).valueOf() > moment().valueOf() && type !== "last") {
      input.data = TrafficData.Forecast;
    } else {
      input.data = TrafficData.Historical;
    }

    return TrafficAPI.tiles.traffic(input);
  }

  stopUpdateTimeout = () => {
    if (!this.updateTimeout) return;
    clearTimeout(this.updateTimeout);
  };

  startUpdateTimeout = () => {
    this.updateTimeout = setTimeout(() => {
      this.remove();
      this.add();
    }, updateTime);
  };

  addLayer() {
    const layer = {
      "id": TRAFFIC_ID,
      "type": "line",
      "source": TRAFFIC_ID,
      "source-layer": "edges",
      "layout": this.properties.getLayout(),
      "paint": {
        "line-color": [
          "coalesce",
          ["get", "color"],
          this.properties.getTrafficLineColors(this.data.isEmptyPieces ?? this.isEmptyPieces),
        ],
        "line-offset": this.properties.getLineOffset(),
        "line-width": this.properties.getLineWidth(),
      },
    };
    const beforeId = getBeforeId(layerId, this.map);
    this.map.addLayer(layer, beforeId);
    hideCurrentTrafficLayer(this.map, this.data.correlation);
  }

  addSource() {
    if (this.data) {
      const url = this.getUrl();
      this.map.addSource(layerId, {
        type: "vector",
        tiles: [url],
      });
    }
  }

  remove() {
    this.stopUpdateTimeout();
    this.arrowLayer.remove();
    if (this.checkLayer()) this.map.removeLayer(this.layerId);

    if (this.checkSource()) this.map.removeSource(this.layerId);
  }

  add() {
    this.stopUpdateTimeout();
    this.addSource();
    this.arrowLayer.addLayer();
    this.addLayer();
    if (this.data?.type !== TrafficPeriod.Last) return;
    this.startUpdateTimeout();
  }

  update(data) {
    this.data = data;
    this.remove();
    this.add();
  }

  setIsEmptyPieces = (isEmptyPieces) => {
    if (!this.map?.getLayer(this.layerId)) return;
    this.isEmptyPieces = isEmptyPieces;
    this.map?.setPaintProperty(this.layerId, "line-color", this.properties.getTrafficLineColors(this.isEmptyPieces));
  };
}
