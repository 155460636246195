import * as turf from "@turf/turf";
import { FullPosition, RnisFullPosition } from "api/telemetry/model/models";
import { RnisTelemetryFeatureProperties, TelemetryFeatureProperties } from "features/ctrl-telemetry/types";

export function getBindPointsData(positions: FullPosition[]) {
  const features = positions.map((position) => {
    const speed = position.speed! / 10;
    return turf.point<TelemetryFeatureProperties>([position.longitude, position.latitude], {
      esid: position.externalSystemID ?? 0,
      bearing: position.bearing ?? 0,
      speed,
      tmid: position.externalTerminalID ?? 0,
      unitid: position.externalUnitID?.toString() ?? "0",
      time: position.dateTime,
      radius: 0,
      color: "",
    });
  });

  // @ts-ignore
  return features;
}

export function getRnisBindPointsData(positions: RnisFullPosition[]) {
  return positions.map((position) => {
    return turf.point<RnisTelemetryFeatureProperties>([position.lng, position.lat], {
      om_id: position.vehicleId,
      tt: position.tt,
      dir: position.dir,
      speed: position.speed,
      grz: position.plate,
    });
  });
}
