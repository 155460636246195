import React from "react";
import { Checkbox } from "components";
import * as hooks from "./interval.hooks";
import * as consts from "./interval.consts";
import "./interval.scss";

export const Interval = React.memo(() => {
  const logicData = hooks.useIsShowIntervalLogic();

  return (
    <div className={consts.baseClass}>
      <Checkbox
        label="Рассчет по интервалу 10 мин"
        value=""
        title="Рассчет по интервалу 10 мин"
        checked={logicData.value}
        onChange={logicData.handleChange}
      />
    </div>
  );
});
