import React, { useEffect, useState } from "react";
import { CloseButton, Tag } from "components";
import { useDispatch, useSelector } from "react-redux";
import { changeCorrelationDayAC, timeUpdateIsActiveAC } from "old-store/view/action-creators";
import { GState } from "documentations";
import moment from "moment";
import {
  BodyTable,
  BodyTitle,
  Cell,
  HeaderTitle,
  Label,
  Message,
  PopupBody,
  PopupContainer,
  PopupHeader,
  RotatableGreenArrow,
  Row,
  StatusBlock,
} from "./history-travel-popup.styled";
import { labelsWithValues, labelsWithValuesBreves, useGetParams } from "./hooks";
import TrafficStatus from "../../ctrl-traffic/components/traffic-status";
import { IDZPrediction } from "../../ctrl-idz/components/idz-prediction";
import { HistoryTravelLabelType } from "../types";
import { historyTravelSlice } from "../store/slice";

const detectorScoreSelector = (state: GState) => state.roadDetector.detectorScore;
const detectorIDZSelector = (state: GState) => state.roadDetector.detectorIDZ;

export const HistoryTravelPopup = () => {
  const dispatch = useDispatch();
  const [formState, setFormState] = useState<boolean>(true);
  const { selectedTime } = useSelector((state: GState) => state.view);
  const { setPositionDetectorPlayer } = historyTravelSlice.actions;
  const { values, isFutureIndexes, isLoad, dynamicsMessages } = useGetParams();
  const { dynamicsId, dynamicsMessage, prevScoreParams, scoreParams } = useSelector(detectorScoreSelector);
  const { dynamicsId: dynamicsIdIDZ, dynamicsMessage: dynamicsMessageIDZ } = useSelector(detectorIDZSelector);

  const averageSpeed = scoreParams?.averageSpeed === undefined ? 0 : scoreParams.averageSpeed;
  const prevAverageSpeed = prevScoreParams?.averageSpeed === undefined ? 0 : prevScoreParams.averageSpeed;

  const handleClickCloseButton = () => {
    dispatch(timeUpdateIsActiveAC(false));
  };

  const isPastFuture = isFutureIndexes.includes(0);
  const isNowFuture = isFutureIndexes.includes(1);
  const isFutureFuture = isFutureIndexes.includes(2);

  useEffect(() => {
    return () => {
      const sevenDaysAgo = moment().subtract(7, "days").format("YYYY-MM-DDT08:00:00");
      dispatch(changeCorrelationDayAC(sevenDaysAgo));
    };
  }, []);

  useEffect(() => {
    dispatch(setPositionDetectorPlayer(formState));
  }, [formState]);

  function getUnitForLabel(label: HistoryTravelLabelType) {
    switch (label) {
      case HistoryTravelLabelType.AverageSpeed:
        return "км/ч";
      case HistoryTravelLabelType.Density:
        return "ед/км";
      case HistoryTravelLabelType.Intensity:
        return "ед/ч";
      case HistoryTravelLabelType.OccupiedRoad:
        return "%";
      case HistoryTravelLabelType.IDZ:
        return "%";
      default:
        return "";
    }
  }

  return (
    <PopupContainer className={!formState ? "history-travel-popup-custom-height" : ""}>
      <PopupHeader>
        <HeaderTitle>Показатели транспортного потока</HeaderTitle>
        <CloseButton onClick={handleClickCloseButton} />
      </PopupHeader>
      <PopupBody>
        <BodyTitle className="history-travel-popup" onClick={() => setFormState(!formState)}>
          Показатели транспортного потока
          <RotatableGreenArrow className={formState ? "" : "rotated"}></RotatableGreenArrow>
        </BodyTitle>
        {formState ? (
          <BodyTable>
            <Row className={"history-travel-popup-custom-grid"}>
              <div />
              <Cell isFuture={isPastFuture}>
                <Label>На час раньше {isPastFuture && " (прогноз)"}</Label>
              </Cell>
              <Cell isFuture={isNowFuture}>
                <Label>Сейчас {isNowFuture && " (прогноз)"}</Label>
              </Cell>
              <Cell isFuture={isFutureFuture}>
                <Label>На час позже {isFutureFuture && " (прогноз)"}</Label>
              </Cell>
            </Row>
            {labelsWithValues.map((v, index) => (
              <Row
                className={"history-travel-popup-custom-grid"}
                key={v.value}
                withBorder={index !== labelsWithValues.length - 1}>
                <Label>
                  {v.label}
                  {v.value === "averageDensityArray" && (
                    <Tag title="Приведённая к легковым ТС" className="history-travel-popup-tag">
                      легк.
                    </Tag>
                  )}
                  {v.value === "averageVolumeArray" && (
                    <Tag title="Приведённая к легковым ТС" className="history-travel-popup-tag">
                      легк.
                    </Tag>
                  )}
                  {v.value === "scoreArray" && <Message>{dynamicsMessages?.scoreMSG}</Message>}
                  {v.value === "alternativeScoreArray" && <Message>{dynamicsMessages?.alternativeScoreMSG}</Message>}
                </Label>
                {!isLoad &&
                  values[v.value].map((item) => {
                    return (
                      <Cell isFuture={moment().diff(item.time) < 0}>
                        {item.value}
                        {v.value === "alternativeScoreArray" && moment(selectedTime).diff(item.time) === 0 && (
                          <StatusBlock left={57}>
                            <IDZPrediction dynamicsID={dynamicsIdIDZ} dynamicsMessage={dynamicsMessageIDZ} />
                          </StatusBlock>
                        )}
                        {v.value === "scoreArray" && moment(selectedTime).diff(item.time) === 0 && (
                          <StatusBlock>
                            <TrafficStatus
                              dynamicsID={dynamicsId}
                              dynamicsMessage={dynamicsMessage}
                              averageSpeed={averageSpeed}
                              prevAverageSpeed={prevAverageSpeed}
                            />
                          </StatusBlock>
                        )}
                      </Cell>
                    );
                  })}
              </Row>
            ))}
          </BodyTable>
        ) : (
          <BodyTable className={"history-travel-popup-info"}>
            {labelsWithValuesBreves.map((v) => (
              <Row className={"history-travel-popup-info"} key={v.value}>
                <Label>{v.label}</Label>
                {!isLoad &&
                  values[v.value].map((item, index) => {
                    return (
                      <Cell
                        className={"cell-history-travel-popup"}
                        key={item.time}
                        isFuture={moment().diff(item.time) < 0}>
                        {index === 1 && (
                          <>
                            {item.value}{" "}
                            <Cell className={"cell-history-travel-popup-custom-color"}>
                              {getUnitForLabel(v.label as HistoryTravelLabelType)}
                            </Cell>
                          </>
                        )}
                      </Cell>
                    );
                  })}
              </Row>
            ))}
          </BodyTable>
        )}
        <BodyTitle>История и прогноз пробок</BodyTitle>
      </PopupBody>
    </PopupContainer>
  );
};
