import { useStoreContext } from "store";

export const useIsNumberOfEdgesLogic = () => {
  const { store } = useStoreContext();
  const value = store.applicationSettings.hooks.useIsNumberOfEdges();

  return {
    value,
    handleChange: store.applicationSettings.events.handleNumberOfEdges,
  };
};
