import { TitleWithIconBlock, HeaderTitleBlock, HeaderNameBlock, HeaderAddress } from "../../detector-popup.styled";
import { GreenDetectorItem, OrangeDetectorItem, GreyDetectorItem } from "assets";
import { overTime } from "../../utils";

export const DetectorHeader = (props: {
  tileData: { id: string; address: string; num: string };
  lastTime?: string;
  isFullMode: boolean;
  showGreyIcon: boolean;
}) => {
  const { tileData, lastTime, isFullMode, showGreyIcon } = props;
  const isTimeOver = overTime(lastTime);
  const detectorHeaderIcon = showGreyIcon ? (
    <GreyDetectorItem />
  ) : isTimeOver || !lastTime ? (
    <OrangeDetectorItem />
  ) : (
    <GreenDetectorItem />
  );

  return (
    <TitleWithIconBlock>
      {detectorHeaderIcon}
      <HeaderTitleBlock>
        <HeaderNameBlock>
          <div className="num">{tileData.id}</div>
          <div className="name">Детектор</div>
        </HeaderNameBlock>
        <HeaderAddress title={tileData.address} isFullMode={isFullMode}>
          {tileData.address}
        </HeaderAddress>
      </HeaderTitleBlock>
    </TitleWithIconBlock>
  );
};
